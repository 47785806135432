import classnames from "classnames";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
} from "reactstrap";
import { logout } from "services/authService";
import i18next from "i18next";
import { languages } from "shared/constants";
import { useTranslation } from "react-i18next";
import { SWITCH_LANGUAGE, SIGN_OUT } from "shared/translation";

function AdminNavbar({ theme }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [showBack, setBack] = useState(false);
  const handleLogout = (e) => {
    e.preventDefault();
    logout()
      .then(() => {
        history.push("/auth/login");
      })
      .catch((err) => {
        console.log(err);
        history.push("/auth/login");
      });
  };

  useEffect(() => {
    displayBackButton(history.location);
    return history.listen((location, action) => {
      displayBackButton(location);
    });
  }, []);

  const displayBackButton = (location) => {
    if (
      location.pathname.startsWith("/admin/add-unit") ||
      location.pathname.startsWith("/admin/add-biomarker") ||
      location.pathname.startsWith("/admin/edit-unit") ||
      location.pathname.startsWith("/admin/edit-biomarker")
    ) {
      setBack(true);
    } else {
      setBack(false);
    }
  };

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand-end",
          { "navbar-dark bg-info-bh-dark": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" },
          { "navbar-expand-space": showBack === true }
        )}
      >
        {showBack && (
          <div
            role="button"
            className="mx-5 text-white"
            onClick={() => {
              if (
                history.location.pathname.startsWith("/admin/add-biomarker") ||
                history.location.pathname.startsWith("/admin/edit-biomarker")
              ) {
                history.push("/admin/biomarkers");
              } else if (
                history.location.pathname.startsWith("/admin/add-unit") ||
                history.location.pathname.startsWith("/admin/edit-unit")
              ) {
                history.push("/admin/units");
              }
            }}
          >
            <img
              alt="back"
              src={
                i18next.language === languages.en ? "/back.png" : "/back-de.png"
              }
              className={i18next.language === languages.en ? "backBtn": "backBtnLarge"}
            />
          </div>
        )}
        <div className="mx-5">
          <UncontrolledDropdown nav>
            <DropdownToggle
              role="button"
              className="nav-link pr-0"
              color=""
              tag="a"
            >
              <img src="/admin.png" className="adminImg" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={(e) =>
                  i18next.changeLanguage(
                    i18next.language === languages.en
                      ? languages.de
                      : languages.en
                  )
                }
              >
                <div className="d-flex align-items-center">
                  <img src="/language.svg" />
                  <span>{t(SWITCH_LANGUAGE)}</span>
                </div>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={(e) => handleLogout(e)}>
                <div className="d-flex align-items-center">
                  <img src="/signout.svg" />
                  <span className="signout">{t(SIGN_OUT)}</span>
                </div>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
