import React, { useEffect, useState } from 'react';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { Card, CardHeader, Container, Row, Col, Input } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { fetchOne, update, add } from 'services/biomarkerService';
import {
  defaultBiomarker,
  updateToast,
  successToast,
  errorToast,
  biomarkerTypes,
  reference_symbols,
  genders,
  validateNodes,
} from 'shared/constants';
import SpinnerLoader from 'components/Misc/Spinner';
import toast from 'react-hot-toast';
import Select2 from 'react-select2-wrapper';
import { fetchAllNonPaged } from 'services/unitService';
import { MultiDropdown } from 'components/Misc/MultiDropdown';
import { useTranslation } from 'react-i18next';
import {
  ADD_NEW_CONVERSION_UNIT,
  BIOMARKER_DETAILS,
  REFERENCE_RANGE_DETAILS,
  UNIT_TO_SI_CONVERSION,
} from 'shared/translation';
import {
  BIOMARKER_NAME,
  BIOMARKER_ABBREVIATION,
  BIOMARKER_ALTERNATIVE_NAMES,
  BIOMARKER_ENGLISH,
  DEFAULT_UNIT,
  BIOMARKER_TYPE,
  CONVERSION_UNITS,
  BIOMARKER_INFORMATION,
  BIOMARKER_INCREASED_INFORMATION,
  BIOMARKER_DECREASED_INFORMATION,
  BIOMARKER_RECOMMENDATIONS,
  MINIMUM_AGE,
  MAXIMUM_AGE,
  GENDER,
  MINIMUM_VALUE,
  REFERENCE_RANGE_SYMBOL,
  MAXIMUM_VALUE,
  SI_UNIT,
  CONVERSION_FACTOR,
  UPDATE_BIOMARKER,
  CREATE_BIOMARKER,
  BIOMARKER_UPDATE_MESSAGE,
  BIOMARKER_CREATED_MESSAGE,
  ERROR_MESSAGE,
  DEFAULT_UNIT_SELECTION_MESSAGE,
  ADD_NEW_REFERENCE_RANGE,
  DELETE_BIOMARKER_CONVERSION_UNIT_MESSAGE,
  DELETE_BIOMARKER_CONVERSION_UNIT_TITLE,
  DELETE_REFERENCE_RANGE_TITLE,
  DELETE_REFERENCE_RANGE_MESSAGE,
  FORM_PROMPT,
} from 'shared/translation';
import { useHistory } from 'react-router-dom';
import TagsInput from 'react-tagsinput';
import DeleteModal from 'components/Modals/DeleteModal';
import { Prompt } from 'react-router';
import * as _ from 'lodash';
import { OVERLAPP_RR } from 'shared/translation';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ENABLE_RECOMMENDATIONS } from 'shared/translation';

const AddBiomarker = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();

  //form values states
  const [form, setFormValue] = useState(defaultBiomarker);
  const [biomarker, setBiomarker] = useState(defaultBiomarker);
  const [units, setUnits] = useState([]);
  const [showSpinner, setSpinner] = useState(true);
  const [isSaved, setSaved] = useState(false);
  const [focused, setFocused] = useState(true);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  //validation states
  const [showError, setErrorMessage] = useState(false);

  //delete states
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(false);

  const [deleteTexts, setDeleteTexts] = useState({ title: '', message: '' });

  useEffect(() => {
    fetchUnits();
    if (id) {
      fetchBiomarker();
    } else {
      setSpinner(false);
      addReferenceRange();
    }
  }, []);

  const fetchUnits = async () => {
    fetchAllNonPaged()
      .then((data) => setUnits(data))
      .catch((err) => console.log(err));
  };

  const fetchBiomarker = async () => {
    fetchOne(id)
      .then((data) => {
        setFormValue(data);
        setBiomarker(data);
        setSpinner(false);
      })
      .catch((err) => console.log(err));
  };

  const validateReferenceRanges = (reference_range) => {
    for (let i = 0; i < reference_range.length; i++) {
      const rr = reference_range[i];
      const sameGenderRR = reference_range.filter(
        (_rr, _index) =>
          i !== _index && _rr.gender.some((value) => rr.gender.includes(value))
      );
      for (let j = 0; j < sameGenderRR.length; j++) {
        const sameRR = sameGenderRR[j];
        if (rr.min_age <= sameRR.max_age && rr.max_age >= sameRR.min_age) {
          return false;
        }
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);
    try {
      if (!validateNodes({ ...form }, ['si_conversion', 'conversion_units'])) {
        setSpinner(false);
        setErrorMessage(true);
        return;
      }
      console.log('submitting');
      if (!validateReferenceRanges(form.reference_range)) {
        setSpinner(false);
        toast.error(t(OVERLAPP_RR), errorToast);
        return;
      }
      const selectedUnitConversions = units
        .find((unit) => unit._id === form.default_unit)
        .conversion.map((unit) => unit.unit);

      const unselectedUnits = selectedUnitConversions.filter(
        (unit) => !form.conversion_units.includes(unit)
      );
      if (id) {
        await update(id, {
          ...form,
          unselected_units: unselectedUnits,
        });
        toast.success(t(BIOMARKER_UPDATE_MESSAGE), updateToast);
      } else {
        await add({
          ...form,
          unselected_units: unselectedUnits,
        });
        toast.success(t(BIOMARKER_CREATED_MESSAGE), successToast);
      }
      setErrorMessage(false);
      setSpinner(false);
      setSaved(true);
      history.push('/admin/biomarkers');
    } catch (err) {
      console.log(err);
      setSpinner(false);
      toast.error(t(ERROR_MESSAGE), errorToast);
    }
  };

  const addUnit = async () => {
    if (!form.default_unit) {
      toast.error(t(DEFAULT_UNIT_SELECTION_MESSAGE), updateToast);
      return;
    }
    setFormValue({
      ...form,
      si_conversion: [
        ...form.si_conversion,
        {
          default_unit: form.default_unit,
          si_unit: '',
          factor: '',
        },
      ],
    });
  };

  const addReferenceRange = () => {
    setFormValue({
      ...form,
      reference_range: [
        ...form.reference_range,
        {
          min_age: '',
          max_age: '',
          gender: [],
          min_value: '',
          symbol: '',
          max_value: '',
        },
      ],
    });
  };

  const defaultUnitChange = (e) => {
    const selectedUnit = units.find((unit) => unit._id === e.params.data.id);
    setFormValue({
      ...form,
      ...{ default_unit: e.params.data.id },
      si_conversion: form.si_conversion.map((val) => {
        val.default_unit = e.params.data.id;
        return val;
      }),
      ...(selectedUnit && {
        conversion_units: selectedUnit.conversion.map((unit) => unit.unit),
      }),
    });
  };

  const mapUnits = () => {
    const conversionUnit = units.find((unit) => unit._id === form.default_unit);
    let allowedUnits = [];
    if (conversionUnit) {
      conversionUnit?.conversion?.forEach((unit) =>
        allowedUnits.push(unit?.unit)
      );
    }
    return units
      .filter((unit) => allowedUnits.includes(unit._id))
      .map((unit) => ({
        label: unit.abbreviation,
        value: unit._id,
      }));
  };

  const deleteClick = (index, isReferenceRange) => {
    if (isReferenceRange) {
      setDeleteTexts({
        title: t(DELETE_REFERENCE_RANGE_TITLE),
        message: t(DELETE_REFERENCE_RANGE_MESSAGE),
      });
    } else {
      setDeleteTexts({
        title: t(DELETE_BIOMARKER_CONVERSION_UNIT_TITLE),
        message: t(DELETE_BIOMARKER_CONVERSION_UNIT_MESSAGE),
      });
    }
    setDeleteIndex({ index, isReferenceRange });
    setDeleteModal(true);
  };

  const handleDelete = () => {
    if (deleteIndex.isReferenceRange) {
      let referenceRangeCopy = [...form.reference_range];
      referenceRangeCopy.splice(deleteIndex.index, 1);
      setFormValue({
        ...form,
        reference_range: referenceRangeCopy,
      });
    } else {
      let conversionCopy = [...form.si_conversion];
      conversionCopy.splice(deleteIndex.index, 1);
      setFormValue({
        ...form,
        si_conversion: conversionCopy,
      });
    }
    setDeleteModal(false);
  };

  const editorConfiguration = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
    ],
  };

  return (
    <>
      <Prompt
        when={!isSaved && !_.isEqual(biomarker, form)}
        message={t(FORM_PROMPT)}
      />
      <DeleteModal
        open={showDeleteModal}
        handleClose={() => setDeleteModal(false)}
        handleDelete={handleDelete}
        message={deleteTexts.message}
        title={deleteTexts.title}
      />
      <SpinnerLoader showSpinner={showSpinner} />
      <SimpleHeader name={id ? t(UPDATE_BIOMARKER) : t(CREATE_BIOMARKER)} />
      {!showSpinner && (
        <Container className="mt--6" fluid>
          <Row>
            <div className="col-lg-12 col-md-12">
              <Card className="pb-2">
                <CardHeader className="border-0">
                  <p className="mb-0 main-dark-color">{t(BIOMARKER_DETAILS)}</p>
                </CardHeader>
                <div>
                  <Row className="px-4 py-3">
                    <Col md="12" lg="6">
                      <h4 className="headingColor">{t(BIOMARKER_NAME)}</h4>
                      <Input
                        className={!form.name && showError ? 'is-invalid' : ''}
                        type="text"
                        value={form.name}
                        onChange={(e) =>
                          setFormValue({ ...form, ...{ name: e.target.value } })
                        }
                      />
                    </Col>
                    <Col md="12" lg="6">
                      <h4 className="headingColor">
                        {t(BIOMARKER_ABBREVIATION)}
                      </h4>
                      <TagsInput
                        value={form.abbreviation}
                        inputProps={{ placeholder: '' }}
                        onChange={(e) =>
                          setFormValue({
                            ...form,
                            ...{ abbreviation: e.filter((v) => v != '') },
                          })
                        }
                        addOnBlur={true}
                      />
                    </Col>
                  </Row>

                  <Row className="px-4 py-3">
                    <Col md="12" lg="6">
                      <h4 className="headingColor">
                        {t(BIOMARKER_ALTERNATIVE_NAMES)}
                      </h4>
                      <TagsInput
                        value={form.alternate_names}
                        inputProps={{ placeholder: '' }}
                        onChange={(e) =>
                          setFormValue({
                            ...form,
                            ...{ alternate_names: e.filter((v) => v != '') },
                          })
                        }
                        addOnBlur={true}
                      />
                    </Col>
                    <Col md="12" lg="6">
                      <h4 className="headingColor">{t(BIOMARKER_ENGLISH)}</h4>
                      <Input
                        className={
                          !form.english && showError ? 'is-invalid' : ''
                        }
                        type="text"
                        value={form.english}
                        onChange={(e) =>
                          setFormValue({
                            ...form,
                            ...{ english: e.target.value },
                          })
                        }
                      />
                    </Col>
                  </Row>

                  <Row className="px-4 py-3">
                    <Col md="12" lg="6">
                      <h4 className="headingColor">{t(DEFAULT_UNIT)}</h4>
                      <div
                        className={
                          !form.default_unit && showError ? 'is-invalid' : ''
                        }
                      >
                        <Select2
                          disabled={id ? true : false}
                          value={form.default_unit}
                          className="form-control"
                          onSelect={defaultUnitChange}
                          data={
                            units
                              ? units.map((unit) => ({
                                  text: unit.abbreviation,
                                  id: unit._id,
                                }))
                              : []
                          }
                        />
                      </div>
                      <i
                        role={'button'}
                        className="fa fa-chevron-down dropdownIcon"
                      ></i>
                    </Col>
                    <Col md="12" lg="6">
                      <h4 className="headingColor">{t(BIOMARKER_TYPE)}</h4>
                      <div>
                        <MultiDropdown
                          key={form.type}
                          value={form.type}
                          options={biomarkerTypes}
                          valueChange={(data) =>
                            setFormValue({
                              ...form,
                              ...{ type: data },
                            })
                          }
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="px-4 py-3">
                    <Col>
                      <h4 className="headingColor">{t(CONVERSION_UNITS)}</h4>
                      <MultiDropdown
                        key={form.conversion_units}
                        value={mapUnits().length ? form.conversion_units : []}
                        options={mapUnits()}
                        valueChange={(data) => {
                          setFormValue({
                            ...form,
                            ...{ conversion_units: data },
                          });
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="px-4 py-3">
                    <Col>
                      <h4 className="headingColor">
                        {t(BIOMARKER_INFORMATION)}
                      </h4>
                      <CKEditor
                        config={editorConfiguration}
                        editor={ClassicEditor}
                        data={form.info}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFormValue({
                            ...form,
                            ...{ info: data },
                          });
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="px-4 py-3">
                    <Col>
                      <h4 className="headingColor">
                        {t(BIOMARKER_INCREASED_INFORMATION)}
                      </h4>
                      <CKEditor
                        config={editorConfiguration}
                        editor={ClassicEditor}
                        data={form.increased_info}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFormValue({
                            ...form,
                            ...{ increased_info: data },
                          });
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="px-4 py-3">
                    <Col>
                      <h4 className="headingColor">
                        {t(BIOMARKER_DECREASED_INFORMATION)}
                      </h4>
                      <CKEditor
                        config={editorConfiguration}
                        editor={ClassicEditor}
                        data={form.decreased_info}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFormValue({
                            ...form,
                            ...{ decreased_info: data },
                          });
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="px-4 py-3">
                    <Col>
                      <h4 className="headingColor">
                        {t(ENABLE_RECOMMENDATIONS)}
                      </h4>
                      <label className="custom-toggle">
                        <input
                          role="button"
                          onClick={() => {
                            setFormValue((prev) => ({
                              ...prev,
                              ...{
                                is_recommendations_enabled:
                                  !prev.is_recommendations_enabled,
                              },
                            }));
                          }}
                          defaultChecked={form.is_recommendations_enabled}
                          type="checkbox"
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </Col>
                  </Row>

                  <Row className="px-4 py-3">
                    <Col>
                      <h4 className="headingColor">
                        {t(BIOMARKER_RECOMMENDATIONS)}
                      </h4>
                      <CKEditor
                        config={editorConfiguration}
                        disabled={!form.is_recommendations_enabled}
                        editor={ClassicEditor}
                        data={form.recommendations}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFormValue({
                            ...form,
                            ...{ recommendations: data },
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Card>

              <Card className="pb-2">
                <CardHeader className="border-0">
                  <p className="mb-0 main-dark-color">
                    {t(REFERENCE_RANGE_DETAILS)}
                  </p>
                </CardHeader>
                {form.reference_range.length ? (
                  form.reference_range.map((reference_range, index) => (
                    <Row key={form?.reference_range[index]}>
                      <Col lg="11">
                        <Row className="px-4 py-3">
                          <Col md="12" lg="4">
                            <h4 className="headingColor">{t(MINIMUM_AGE)}</h4>
                            <Input
                              className={
                                !form.reference_range[index].min_age &&
                                showError
                                  ? 'is-invalid'
                                  : ''
                              }
                              type="number"
                              value={form.reference_range[index].min_age}
                              onChange={(e) =>
                                setFormValue({
                                  ...form,
                                  reference_range: Object.assign(
                                    form.reference_range,
                                    {
                                      [index]: {
                                        ...form.reference_range[index],
                                        min_age: parseInt(e.target.value),
                                      },
                                    }
                                  ),
                                })
                              }
                            />
                          </Col>
                          <Col md="12" lg="4">
                            <h4 className="headingColor">{t(MAXIMUM_AGE)}</h4>
                            <Input
                              className={
                                !form.reference_range[index].max_age &&
                                showError
                                  ? 'is-invalid'
                                  : ''
                              }
                              type="number"
                              value={form.reference_range[index].max_age}
                              onChange={(e) =>
                                setFormValue({
                                  ...form,
                                  reference_range: Object.assign(
                                    form.reference_range,
                                    {
                                      [index]: {
                                        ...form.reference_range[index],
                                        max_age: parseInt(e.target.value),
                                      },
                                    }
                                  ),
                                })
                              }
                            />
                          </Col>
                          <Col md="12" lg="4">
                            <h4 className="headingColor">{t(GENDER)}</h4>
                            <div
                              className={
                                !form.reference_range[index].gender.length &&
                                showError
                                  ? 'is-invalid'
                                  : ''
                              }
                            >
                              <MultiDropdown
                                key={form.reference_range[index].gender}
                                value={form.reference_range[index].gender}
                                options={genders}
                                valueChange={(data) =>
                                  setFormValue({
                                    ...form,
                                    reference_range: Object.assign(
                                      form.reference_range,
                                      {
                                        [index]: {
                                          ...form.reference_range[index],
                                          gender: data,
                                        },
                                      }
                                    ),
                                  })
                                }
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="px-4 py-3">
                          <Col md="12" lg="4">
                            <h4 className="headingColor">{t(MINIMUM_VALUE)}</h4>
                            <Input
                              className={
                                !form.reference_range[index].min_value &&
                                showError
                                  ? 'is-invalid'
                                  : ''
                              }
                              type="text"
                              value={form.reference_range[index].min_value}
                              disabled={
                                form.reference_range[index].symbol !== '' &&
                                form.reference_range[index].symbol !== '-' &&
                                !focused
                              }
                              onFocus={onFocus}
                              onBlur={onBlur}
                              onChange={(e) =>
                                setFormValue({
                                  ...form,
                                  reference_range: Object.assign(
                                    form.reference_range,
                                    {
                                      [index]: {
                                        ...form.reference_range[index],
                                        min_value: e.target.value.replace(
                                          /[^0-9\.\,]/,
                                          ''
                                        ),
                                      },
                                    }
                                  ),
                                })
                              }
                            />
                          </Col>
                          <Col md="12" lg="4">
                            <h4 className="headingColor">
                              {t(REFERENCE_RANGE_SYMBOL)}
                            </h4>
                            <div
                              className={
                                !form.reference_range[index].symbol && showError
                                  ? 'is-invalid'
                                  : ''
                              }
                              onClick={() =>
                                setFormValue({
                                  ...form,
                                  reference_range: Object.assign(
                                    form.reference_range,
                                    {
                                      [index]: {
                                        ...form.reference_range[index],
                                        symbol: '',
                                      },
                                    }
                                  ),
                                })
                              }
                            >
                              <Select2
                                value={form.reference_range[index].symbol}
                                className="form-control"
                                data-minimum-results-for-search="Infinity"
                                onSelect={(e) =>
                                  setFormValue({
                                    ...form,
                                    reference_range: Object.assign(
                                      form.reference_range,
                                      {
                                        [index]: {
                                          ...form.reference_range[index],
                                          symbol: e.params.data.text,
                                        },
                                      }
                                    ),
                                  })
                                }
                                data={reference_symbols}
                              />
                              <i
                                role={'button'}
                                className="fa fa-chevron-down dropdownIcon"
                              ></i>
                            </div>
                          </Col>
                          <Col md="12" lg="4">
                            <h4 className="headingColor">{t(MAXIMUM_VALUE)}</h4>
                            <Input
                              className={
                                !form.reference_range[index].max_value &&
                                showError
                                  ? 'is-invalid'
                                  : ''
                              }
                              type="text"
                              value={form.reference_range[index].max_value}
                              onChange={(e) =>
                                setFormValue({
                                  ...form,
                                  reference_range: Object.assign(
                                    form.reference_range,
                                    {
                                      [index]: {
                                        ...form.reference_range[index],
                                        max_value: e.target.value.replace(
                                          /[^0-9\.\,]/,
                                          ''
                                        ),
                                      },
                                    }
                                  ),
                                })
                              }
                            />
                          </Col>
                        </Row>
                        <hr className="mx-5" />
                      </Col>

                      {index !== 0 && (
                        <Col
                          lg="1"
                          className="d-flex align-items-center justify-content-center"
                        >
                          <i
                            role={'button'}
                            className="ml--5 mt--4 fas fa-trash linkText"
                            onClick={() => deleteClick(index, true)}
                          ></i>
                        </Col>
                      )}
                    </Row>
                  ))
                ) : (
                  <></>
                )}
                <p
                  className="mx-4 mb-2 linkText mt-4"
                  role="button"
                  onClick={addReferenceRange}
                >
                  <i className="fa fa-plus mr-2"></i>
                  <span>{t(ADD_NEW_REFERENCE_RANGE)}</span>
                </p>
              </Card>

              <Card className="pb-2">
                <CardHeader className="border-0">
                  <p className="mb-0 main-dark-color">
                    {t(UNIT_TO_SI_CONVERSION)}
                  </p>
                  {form.si_conversion.length ? (
                    form.si_conversion.map((unit, index) => (
                      <Row key={form.si_conversion[index]}>
                        <Col lg="11">
                          <Row className="px-4 py-3">
                            <Col md="12" lg="4">
                              <h4 className="headingColor">
                                {t(DEFAULT_UNIT)}
                              </h4>
                              <Input
                                disabled
                                type="text"
                                onChange={(e) => {}}
                                value={
                                  units.find(
                                    (unit) => unit._id === form.default_unit
                                  )
                                    ? units.find(
                                        (unit) => unit._id === form.default_unit
                                      ).abbreviation
                                    : ''
                                }
                              />
                            </Col>
                            <Col md="12" lg="4">
                              <h4 className="headingColor">{t(SI_UNIT)}</h4>
                              <div
                                className={
                                  !form.si_conversion[index].si_unit &&
                                  showError
                                    ? 'is-invalid'
                                    : ''
                                }
                              >
                                <Select2
                                  value={form.si_conversion[index].si_unit}
                                  className="form-control"
                                  onSelect={(e) =>
                                    setFormValue({
                                      ...form,
                                      si_conversion: Object.assign(
                                        form.si_conversion,
                                        {
                                          [index]: {
                                            ...form.si_conversion[index],
                                            si_unit: e.params.data.id,
                                          },
                                        }
                                      ),
                                    })
                                  }
                                  data={
                                    units
                                      ? units
                                          .filter(
                                            (unit) =>
                                              unit._id !== form.default_unit
                                          )
                                          .map((unit) => ({
                                            text: unit.abbreviation,
                                            id: unit._id,
                                          }))
                                      : []
                                  }
                                />
                                <i
                                  role={'button'}
                                  className="fa fa-chevron-down dropdownIcon"
                                ></i>
                              </div>
                            </Col>
                            <Col md="12" lg="4">
                              <h4 className="headingColor">
                                {t(CONVERSION_FACTOR)}
                              </h4>
                              <Input
                                className={
                                  !form.si_conversion[index].factor && showError
                                    ? 'is-invalid'
                                    : ''
                                }
                                type="text"
                                value={form.si_conversion[index].factor}
                                onChange={(e) =>
                                  setFormValue({
                                    ...form,
                                    si_conversion: Object.assign(
                                      form.si_conversion,
                                      {
                                        [index]: {
                                          ...form.si_conversion[index],
                                          factor: e.target.value.replace(
                                            /[^0-9\.\,]/,
                                            ''
                                          ),
                                        },
                                      }
                                    ),
                                  })
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          lg="1"
                          className="d-flex align-items-center justify-content-center"
                        >
                          <i
                            role={'button'}
                            className="mt-4 fas fa-trash linkText"
                            onClick={() => deleteClick(index, false)}
                          ></i>
                        </Col>
                      </Row>
                    ))
                  ) : (
                    <></>
                  )}
                  <p
                    className="mb-0 linkText mt-4"
                    role="button"
                    onClick={addUnit}
                  >
                    <i className="fa fa-plus mr-2"></i>
                    <span>{t(ADD_NEW_CONVERSION_UNIT)}</span>
                  </p>
                </CardHeader>
              </Card>

              <Row className="py-2">
                <Col>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="btn default-button-background text-white px-4"
                  >
                    {id ? t(UPDATE_BIOMARKER) : t(CREATE_BIOMARKER)}
                  </button>
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
      )}
    </>
  );
};

export default AddBiomarker;
