import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Input,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { fetchAll } from "services/biomarkerService";
import { useHistory } from "react-router-dom";
import SpinnerLoader from "components/Misc/Spinner";
import { useTranslation } from "react-i18next";
import {
  BIOMARKER_NAME,
  BIOMARKER_ABBREVIATION,
  BIOMARKER_ALTERNATIVE_NAMES,
  BIOMARKER_ENGLISH,
  BIOMARKER_TYPE,
  DEFAULT_UNIT,
  EDIT,
  ITEMS_PER_PAGE,
  BIOMARKERS,
  SEARCH,
} from "shared/translation";

function Biomarkers() {
  const { t } = useTranslation();

  const [pageSize, setPageSize] = useState(10);
  const history = useHistory();
  const [biomarkers, setBiomarkers] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [showSpinner, setSpinner] = useState(true);

  React.useEffect(() => {
    fetchBiomarkers({ skip: 0, take: pageSize });
  }, []);

  const fetchBiomarkers = async (body) => {
    setSpinner(true);
    setBiomarkers([]);
    fetchAll(body)
      .then((data) => {
        setBiomarkers(data.biomarkers);
        setCount(data.count);
        setSpinner(false);
      })
      .catch((err) => console.log(err));
  };

  const pages = () => {
    let pagesArr = [];
    for (let i = 1; i <= Math.ceil(count / pageSize); i++) {
      pagesArr.push(
        <PaginationItem className={currentPage === i ? "active" : ""}>
          <PaginationLink onClick={(e) => handlePageClick(e, i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return pagesArr;
  };

  const handlePageClick = (e, pageNumber) => {
    e.preventDefault();
    fetchBiomarkers({ skip: (pageNumber - 1) * pageSize, take: pageSize });
    setPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    fetchBiomarkers({
      skip: (currentPage - 1) * pageSize,
      take: pageSize,
      ...(e.target.value.trim() && { search: e.target.value.trim() }),
    });
  };

  const pageClick = (e) => {
    setPageSize(parseInt(e.target.value));
    fetchBiomarkers({
      skip: (currentPage - 1) * parseInt(e.target.value),
      take: parseInt(e.target.value),
      ...(search.trim() && {
        search: search.trim(),
      }),
    });
  };

  const editClick = (e, biomarker) => {
    e.preventDefault();
    history.push(`/admin/edit-biomarker/${biomarker._id}`);
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <SimpleHeader name={t(BIOMARKERS)} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Input
                  className="searchBox"
                  placeholder={t(SEARCH)}
                  type="text"
                  value={search}
                  onChange={handleSearch}
                />
              </CardHeader>
              <div className="table-responsive">
                <Table className="dataTable align-items-center">
                  <thead className="thead-bh icon-color-light">
                    <tr>
                      <th className="w-15" scope="col">
                        {t(BIOMARKER_NAME)}
                      </th>
                      <th className="w-20" scope="col">
                        {t(BIOMARKER_ABBREVIATION)}
                      </th>
                      <th className="w-40" scope="col">
                        {t(BIOMARKER_ALTERNATIVE_NAMES)}
                      </th>
                      <th className="w-20" scope="col">
                        {t(DEFAULT_UNIT)}
                      </th>
                      <th className="w-5" scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {biomarkers.map((biomarker, index) => (
                      <tr key={index}>
                        <td className="overflowStyle text-capitalize pl-4">
                          {biomarker.name}
                        </td>
                        <td className="overflowStyle pl-0 pr-4">
                          {biomarker.abbreviation}
                        </td>
                        <td className="overflowStyle text-capitalize pl-0 pr-4">
                          {biomarker.alternate_names}
                        </td>
                        <td className="overflowStyle pl-0 pr-4">
                          {biomarker.default_unit.abbreviation}
                        </td>
                        <td className="actionDropdown px-0">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light action-bg"
                              color=""
                              role="button"
                              size="sm"
                            >
                              <img src="/action.svg" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={(e) => editClick(e, biomarker)}
                              >
                                <div className="d-flex align-items-center justify-content-start">
                                  <div>{t(EDIT)}</div>
                                </div>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                    <tr></tr>
                  </tbody>
                </Table>
                {!showSpinner && !biomarkers.length && (
                  <div className="d-flex align-items-center justify-content-center mb-3">
                    No records found
                  </div>
                )}
              </div>
              <CardFooter className="py-4">
                <nav
                  className="d-flex align-items-center justify-content-between"
                  aria-label="..."
                >
                  <div className="pageEntry">
                    <small className="itemsPerPage">
                      {t(ITEMS_PER_PAGE)}
                      <select
                        defaultValue={10}
                        onChange={pageClick}
                        className="py-1 pl-2 pr-4 ml-1 selectItemsPerPage main-dark-color"
                      >
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                      </select>
                      <i className="main-dark-color fa fa-chevron-down text-xxs"></i>
                      <span className="ml-2"></span>
                    </small>
                  </div>
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem
                      className={currentPage === 1 ? "disabled" : ""}
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, currentPage - 1)}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left icon-color-light" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {pages().map((page, index) => (
                      <div key={index}>{page}</div>
                    ))}
                    <PaginationItem
                      className={
                        currentPage >= Math.ceil(count / pageSize)
                          ? "disabled"
                          : ""
                      }
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, currentPage + 1)}
                      >
                        <i className="fas fa-angle-right icon-color-light" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Biomarkers;
