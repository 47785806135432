export const defaultBiomarker = {
  name: "",
  abbreviation: [],
  alternate_names: [],
  english: "",
  default_unit: "",
  type: [],
  conversion_units: [],
  info: "",
  increased_info: "",
  decreased_info: "",
  recommendations: "",
  reference_range: [],
  si_conversion: [],
};

export const nonCompulsoryFields = [
  "abbreviation",
  "alternate_names",
  "type",
  "info",
  "increased_info",
  "decreased_info",
  "recommendations",
];

export const defaultUnit = {
  abbreviation: "",
  alternate_names: [],
  conversion: [],
};

export const updateToast = {
  className: "updateToast",
  iconTheme: {
    primary: "white",
    secondary: "#3a3a73",
  },
};

export const successToast = {
  className: "successToast",
  iconTheme: {
    primary: "white",
    secondary: "#76d89c",
  },
};

export const errorToast = {
  className: "errorToast",
  iconTheme: {
    primary: "white",
    secondary: "red",
  },
};

export const reference_symbols = ["-", "<=", ">=", "<", ">"];

export const genders = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

export const biomarkerTypes = [
  { value: "Large Blood Count", label: "Large Blood Count" },
  { value: "Small Blood Count", label: "Small Blood Count" },
];

export const languages = {
  en: "en",
  de: "de",
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const validateNodes = (object, deleteArr = [], isBiomarker = true) => {
  deleteArr.forEach((element) => {
    if (element.length === 0) {
      delete object[element];
    }
  });
  let filteredObject;
  if (isBiomarker) {
    filteredObject = deleteNonCompulsoryNodes(object, nonCompulsoryFields);
  } else {
    filteredObject = deleteNonCompulsoryNodes(object, ["alternate_names"]);
  }
  return checkNestedObject(filteredObject);
};

const deleteNonCompulsoryNodes = (object, nodes) => {
  nodes.forEach((node) => {
    delete object[node];
  });
  return object;
};

const checkNestedObject = (object) => {
  const arr = Object.values(object);
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === null) {
      return false;
    } else if (typeof arr[i] === "object") {
      if (checkNestedObject(arr[i]) === false) {
        return false;
      }
    } else if (Array.isArray(arr[i]) && arr[i].length === 0) {
      return false;
    } else if (arr[i] === "") {
      return false;
    }
  }
  return true;
};
