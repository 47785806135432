import React, { useEffect } from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import { login, verifyLogin } from "services/authService";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { errorToast, updateToast } from "shared/constants";
import SpinnerLoader from "components/Misc/Spinner";

function Login() {
  const history = useHistory();

  useEffect(() => {
    verifyLogin()
      .then((verified) => {
        if (verified) {
          history.push("/admin/biomarkers");
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showSpinner, setSpinner] = React.useState(false);

  const handleLogin = async () => {
    setSpinner(true);
    if (!email || !password) {
      toast.error("Provide Credentials to Continue", updateToast);
      setSpinner(false);
      return;
    }
    login(email, password)
      .then(() => {
        setSpinner(false);
        history.push("/admin/biomarkers");
      })
      .catch(() => {
        toast.error("Invalid Email or Password", errorToast);
        setSpinner(false);
      });
  };

  const keyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleLogin();
    }
  };

  return (
    <>
      <SpinnerLoader adminView={true} showSpinner={showSpinner} />
      <AuthHeader />
      <div className="loginForm">
        <Container className="mt-n18">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-default-login border-0 mx-4 mb-0 py-4 px-3">
                <CardBody className="px-lg-4 py-lg-3">
                  <div className="d-flex justify-content-center my-5">
                    <img src={"/bt_logo.png"} className="bt_logo" alt="logo" />
                  </div>
                  <div className="text-center text-muted">
                    <p className="text-dark subhead">Login To Continue</p>
                  </div>
                  <Form role="form">
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: focusedEmail,
                      })}
                    >
                      <InputGroup>
                        <Input
                          placeholder="Email Address"
                          type="email"
                          onFocus={() => setfocusedEmail(true)}
                          onBlur={() => setfocusedEmail(true)}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onKeyDown={keyDown}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames("mb-1", {
                        focused: focusedPassword,
                      })}
                    >
                      <InputGroup>
                        <Input
                          placeholder="Password"
                          type="password"
                          onFocus={() => setfocusedPassword(true)}
                          onBlur={() => setfocusedPassword(true)}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onKeyDown={keyDown}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        className="default-button-background w-100 mt-4 py-2"
                        color="info"
                        type="button"
                        onClick={handleLogin}
                      >
                        <h3 className="mb-0 text-white py-1">Sign In</h3>
                      </Button>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <p
                        onClick={(e) => history.push("/auth/forgot-password")}
                        className="forget-pass px-2 mb-0"
                        role={"button"}
                      >
                        Forgot Password?
                      </p>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Login;
