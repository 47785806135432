import Login from "views/auth/Login.js";
import ForgotPassword from "views/auth/ForgotPassword";
import ResetPassword from "views/auth/ResetPassword";
import Biomarkers from "views/biomarkers/Biomarkers";
import Units from "views/units/Units";
import AddBiomarker from "views/biomarkers/AddBiomarker";
import AddUnit from "views/units/AddUnit";
import { BIOMARKERS, UNITS } from "shared/translation";

export const routes = [
  {
    path: "/login",
    name: "Login",
    miniName: "L",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    miniName: "FP",
    component: ForgotPassword,
    layout: "/auth",
  },
  {
    path: "/reset-password/:id",
    name: "Reset Password",
    miniName: "RP",
    component: ResetPassword,
    layout: "/auth",
  },
];

export const sidebarRoutes = [
  {
    path: "/biomarkers",
    name: BIOMARKERS,
    icon: "/biomarker",
    component: Biomarkers,
    layout: "/admin",
  },
  {
    path: "/units",
    name: UNITS,
    icon: "/unit",
    component: Units,
    layout: "/admin",
  },
  {
    path: "/add-biomarker/",
    name: "Add Biomarker",
    component: AddBiomarker,
    layout: "/admin",
  },
  {
    path: "/add-unit/",
    name: "Add Unit",
    component: AddUnit,
    layout: "/admin",
  },
  {
    path: "/edit-biomarker/:id",
    name: "Edit Biomarker",
    component: AddBiomarker,
    layout: "/admin",
  },
  {
    path: "/edit-unit/:id",
    name: "Edit Unit",
    component: AddUnit,
    layout: "/admin",
  },
];
