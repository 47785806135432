import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import {
  validateResetLink,
  changePasswordWithLink,
} from "services/authService";
import { useHistory, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { errorToast, successToast } from "shared/constants";
import SpinnerLoader from "components/Misc/Spinner";

function ResetPassword() {
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    id ? checkResetLink() : setSpinner(false);
  }, []);

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [showSpinner, setSpinner] = React.useState(true);
  const [validLink, setValidLink] = React.useState(false);

  const checkResetLink = () => {
    validateResetLink(id)
      .then((data) => {
        data.valid
          ? setValidLink(true)
          : toast.error("Link not Valid or Expired", errorToast);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        toast.error("Something went wrong", errorToast);
      });
  };

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      toast.error("Passwords do not Match!", errorToast);
      return;
    }
    if (password.length < 6) {
      toast.error("Passwords must be atleast 6 characters", errorToast);
      return;
    }
    setSpinner(true);
    changePasswordWithLink(password, id)
      .then((data) => {
        !data.success
          ? toast.error("Password Reset Failed", errorToast)
          : toast.success("Password reset Successfully!", successToast);
        setSpinner(false);
        history.push("/auth/login");
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        toast.error("Something went wrong", errorToast);
      });
  };

  return (
    <>
      <SpinnerLoader adminView={true} showSpinner={showSpinner} />
      <AuthHeader />
      <div className="loginForm">
        <Container className="mt-n18">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-default-login border-0 mx-4 mb-0 py-4 px-3">
                <CardBody className="px-lg-4 py-lg-3">
                  <div className="d-flex justify-content-center my-5">
                    <img src={"/bt_logo.png"} className="bt_logo" alt="logo" />
                  </div>
                  <div className="text-center text-muted mt-4 mb-4">
                    <h1 className="text-dark subhead">Reset Password</h1>
                    <p className="forget-pass-text">
                      Enter your new Password in order to reset.
                    </p>
                  </div>
                  <Form role="form">
                    <FormGroup>
                      <InputGroup>
                        <Input
                          disabled={!validLink}
                          placeholder="New Password"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          disabled={!validLink}
                          placeholder="Confirm Password"
                          type="password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        className="default-button-background w-100 mt-4 py-2"
                        color="info"
                        type="button"
                        onClick={handleSubmit}
                      >
                        <h3 className="mb-0 text-white py-1">Reset Password</h3>
                      </Button>
                    </div>
                    <div className="d-flex align-items-center justify-content-center mt-3">
                      <small
                        onClick={(e) => history.push("/auth/login")}
                        className="back-login px-2 mb-0"
                        role={"button"}
                      >
                        Back to Login
                      </small>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ResetPassword;
