import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ADD_NEW } from "shared/translation";

function TimelineHeader({ name }) {
  const history = useHistory();
  const [showAddBtn, setAddBtn] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (
      history.location.pathname === "/admin/units" ||
      history.location.pathname === "/admin/biomarkers"
    ) {
      setAddBtn(true);
    }
  }, []);

  const handleAddClick = () => {
    if (history.location.pathname === "/admin/biomarkers") {
      history.push("/admin/add-biomarker");
    } else if (history.location.pathname === "/admin/units") {
      history.push("/admin/add-unit");
    }
  };

  return (
    <>
      <div className="header header-dark pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6">
                <h6 className="h1 text-dark d-inline-block mb-0 mx-2">
                  {name}
                </h6>
              </Col>
              {showAddBtn && (
                <Col lg="6">
                  <div className="d-flex align-items-center justify-content-end">
                    <button
                      onClick={handleAddClick}
                      className="btn default-button-background text-white px-4"
                    >
                      {t(ADD_NEW)}
                    </button>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;
