import React, { useEffect, useState } from "react";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Card, CardHeader, Container, Row, Col, Input } from "reactstrap";
import { useParams } from "react-router-dom";
import { fetchOneUnit, update, add } from "services/unitService";
import {
  defaultUnit,
  updateToast,
  successToast,
  errorToast,
  validateNodes,
} from "shared/constants";
import SpinnerLoader from "components/Misc/Spinner";
import toast from "react-hot-toast";
import Select2 from "react-select2-wrapper";
import { fetchAllNonPaged } from "services/unitService";
import { useTranslation } from "react-i18next";
import { ADD_NEW } from "shared/translation";
import {
  UNIT_DETAILS,
  UNIT_CONVERSION,
  UPDATE_UNIT,
  CREATE_UNIT,
  UNIT_ABBREVIATION,
  UNIT_ALTERNATIVE_NAMES,
  CONVERTING_UNIT,
  CONVERSION_FACTOR,
  UNIT_UPDATE_MESSAGE,
  UNIT_CREATED_MESSAGE,
  ERROR_MESSAGE,
  DELETE_BIOMARKER_CONVERSION_UNIT_MESSAGE,
  DELETE_BIOMARKER_CONVERSION_UNIT_TITLE,
  FORM_PROMPT,
} from "shared/translation";
import { useHistory } from "react-router-dom";
import TagsInput from "react-tagsinput";
import DeleteModal from "components/Modals/DeleteModal";
import { Prompt } from "react-router";
import * as _ from "lodash";

const AddUnit = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();

  //form values states
  const [form, setFormValue] = useState(defaultUnit);
  const [isSaved, setSaved] = useState(false);
  const [unit, setUnit] = useState(defaultUnit);
  const [units, setUnits] = useState([]);
  const [showSpinner, setSpinner] = useState(true);

  //validation states
  const [showError, setErrorMessage] = useState(false);

  //delete states
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(false);

  useEffect(() => {
    fetchUnits();
    id ? fetchUnit() : setSpinner(false);
  }, []);

  const fetchUnits = async () => {
    fetchAllNonPaged()
      .then((data) => setUnits(data))
      .catch((err) => console.log(err));
  };

  const fetchUnit = async () => {
    fetchOneUnit(id)
      .then((data) => {
        setFormValue({
          ...data,
          alternate_names: data.alternate_names
            ? data.alternate_names.split(",")
            : [],
        });
        setUnit({
          ...data,
          alternate_names: data.alternate_names
            ? data.alternate_names.split(",")
            : [],
        });
        setSpinner(false);
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);
    try {
      if (!validateNodes({ ...form }, ["conversion"], false)) {
        setSpinner(false);
        setErrorMessage(true);
        return;
      }
      if (id) {
        await update(id, {
          ...form,
          alternate_names: form.alternate_names.join(","),
        });
        toast.success(t(UNIT_UPDATE_MESSAGE), updateToast);
      } else {
        await add({ ...form, alternate_names: form.alternate_names.join(",") });
        toast.success(t(UNIT_CREATED_MESSAGE), successToast);
      }
      setErrorMessage(false);
      setSpinner(false);
      setSaved(true);
      history.push("/admin/units");
    } catch (err) {
      console.log(err);
      setSpinner(false);
      toast.error(t(ERROR_MESSAGE), errorToast);
    }
  };

  const addUnit = async () => {
    setFormValue({
      ...form,
      conversion: [
        ...form.conversion,
        {
          unit: "",
          factor: "",
        },
      ],
    });
  };

  const deleteClick = (index) => {
    setDeleteIndex(index);
    setDeleteModal(true);
  };

  const deleteConversionUnit = () => {
    let conversionCopy = [...form.conversion];
    conversionCopy.splice(deleteIndex, 1);
    setFormValue({
      ...form,
      conversion: conversionCopy,
    });
    setDeleteModal(false);
  };

  return (
    <>
      <Prompt
        when={!isSaved && !_.isEqual(unit, form)}
        message={t(FORM_PROMPT)}
      />
      <DeleteModal
        open={showDeleteModal}
        handleClose={() => setDeleteModal(false)}
        handleDelete={deleteConversionUnit}
        message={t(DELETE_BIOMARKER_CONVERSION_UNIT_MESSAGE)}
        title={t(DELETE_BIOMARKER_CONVERSION_UNIT_TITLE)}
      />
      <SpinnerLoader showSpinner={showSpinner} />
      <SimpleHeader name={id ? t(UPDATE_UNIT) : t(CREATE_UNIT)} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col-lg-12 col-md-12">
            <Card className="pb-2">
              <CardHeader className="border-0">
                <p className="mb-0 main-dark-color">{t(UNIT_DETAILS)}</p>
              </CardHeader>
              <div>
                <Row className="px-4 py-3">
                  <Col md="12" lg="6">
                    <h4 className="headingColor">{t(UNIT_ABBREVIATION)}</h4>
                    <Input
                      className={
                        !form.abbreviation && showError ? "is-invalid" : ""
                      }
                      type="text"
                      value={form.abbreviation}
                      onChange={(e) =>
                        setFormValue({
                          ...form,
                          ...{ abbreviation: e.target.value },
                        })
                      }
                    />
                  </Col>
                  <Col md="12" lg="6">
                    <h4 className="headingColor">
                      {t(UNIT_ALTERNATIVE_NAMES)}
                    </h4>
                    <TagsInput
                      value={form.alternate_names}
                      inputProps={{ placeholder: "" }}
                      onChange={(e) =>
                        setFormValue({
                          ...form,
                          ...{ alternate_names: e.filter((v) => v != "") },
                        })
                      }
                      addOnBlur={true}
                    />
                  </Col>
                </Row>
              </div>
            </Card>

            <Card className="pb-2">
              <CardHeader className="border-0">
                <p className="mb-0 main-dark-color">{t(UNIT_CONVERSION)}</p>
                {form.conversion.map((unit, index) => {
                  return (
                    <Row key={index}>
                      <Col md="12" lg="11">
                        <Row className="px-4 py-3">
                          <Col md="12" lg="6">
                            <h4 className="headingColor">
                              {t(CONVERTING_UNIT)}
                            </h4>
                            <div
                              className={
                                !form.conversion[index].unit && showError
                                  ? "is-invalid"
                                  : ""
                              }
                            >
                              <Select2
                                value={form.conversion[index].unit}
                                className="form-control"
                                onSelect={(e) =>
                                  setFormValue({
                                    ...form,
                                    conversion: Object.assign(form.conversion, {
                                      [index]: {
                                        ...form.conversion[index],
                                        unit: e.params.data.id,
                                      },
                                    }),
                                  })
                                }
                                data={
                                  units
                                    ? units.map((unit) => ({
                                        text: unit.abbreviation,
                                        id: unit._id,
                                        disabled:
                                          unit._id === id ||
                                          form.conversion
                                            .map((convert) => convert.unit)
                                            .includes(unit._id),
                                      }))
                                    : []
                                }
                              />
                              <i
                                role={"button"}
                                className="fa fa-chevron-down dropdownIcon"
                              ></i>
                            </div>
                          </Col>
                          <Col md="12" lg="6">
                            <h4 className="headingColor">
                              {t(CONVERSION_FACTOR)}
                            </h4>
                            <Input
                              className={
                                !form.conversion[index].factor && showError
                                  ? "is-invalid"
                                  : ""
                              }
                              type="text"
                              value={form.conversion[index].factor}
                              onChange={(e) =>
                                setFormValue({
                                  ...form,
                                  conversion: Object.assign(form.conversion, {
                                    [index]: {
                                      ...form.conversion[index],
                                      factor: e.target.value.replace(
                                        /[^0-9\.\,]/,
                                        ""
                                      ),
                                    },
                                  }),
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        md="12"
                        lg="1"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <i
                          role={"button"}
                          className="mt-4 fas fa-trash linkText"
                          onClick={() => deleteClick(index)}
                        ></i>
                      </Col>
                    </Row>
                  );
                })}
                <p
                  className="mb-0 linkText mt-4"
                  role="button"
                  onClick={addUnit}
                >
                  <i className="fa fa-plus mr-2"></i>
                  <span>{t(ADD_NEW)}</span>
                </p>
              </CardHeader>
            </Card>

            <Row className="py-2">
              <Col>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="btn default-button-background text-white px-4"
                >
                  {id ? t(UPDATE_UNIT) : t(CREATE_UNIT)}
                </button>
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AddUnit;
